<template>
  <div class="stand-in-header__wrapper">
    <div class="stand-in-header__content">
      <div class="mobile-menu">
        <div
          class="mobile-menu__open-button"
        >
          <span
            class="mobile-menu__x"
          >
            <div />
            <div />
            <div />
          </span>
        </div>
      </div>
      <p class="stand-in-header__title"></p>
      <div class="stand-in-header__items">
        <div class="stand-in-header__item">
            <div class="stand-in-header__item__button__wrapper">
              <div class="stand-in-header__item__button">
              </div>
            </div>
        </div>
        <div class="stand-in-header__item">
            <div class="stand-in-header__item__button__wrapper">
              <div class="stand-in-header__item__button">
              </div>
            </div>
          <div class="stand-in-header-contents__wrapper">
            <div class="stand-in-header-contents__column">
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
            </div>
            <div class="stand-in-header-contents__column">
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
            </div>
          </div>
        </div>
        <div class="stand-in-header__item">
            <div class="stand-in-header__item__button__wrapper">
              <div class="stand-in-header__item__button">
              </div>
            </div>
          <div class="stand-in-header-contents__wrapper">
            <div class="stand-in-header-contents__column">
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
            </div>
            <div class="stand-in-header-contents__column">
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
            </div>
            <div class="stand-in-header-contents__column">
              <div>
                  <p class="stand-in-header-contents__item">
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stand-in-header__other-buttons">
          <div class="other-buttons__secondary">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M6.28 3c3.236.001 4.973 3.491 5.72 5.031.75-1.547 2.469-5.021 5.726-5.021 2.058 0 4.274 1.309 4.274 4.182 0 3.442-4.744 7.851-10 13-5.258-5.151-10-9.559-10-13 0-2.676 1.965-4.193 4.28-4.192zm.001-2c-3.183 0-6.281 2.187-6.281 6.192 0 4.661 5.57 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-4.011-3.097-6.182-6.274-6.182-2.204 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248z"></path>
            </svg>
          </div>
          <div class="other-buttons__main">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="cart">
              <path d="M24 3l-.743 2h-1.929l-3.474 12h-13.239l-4.615-11h16.812l-.564 2h-13.24l2.937 7h10.428l3.432-12h4.195zm-15.5 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.9-7-1.9 7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z"></path>
            </svg>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  @use "../../../../styles/_global-constants" as *;

  .stand-in-header {
    &__wrapper {
      background-color: var(--background_heavy);
      margin-bottom: 1px;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &__content {
      line-height: 50px;
      margin: auto;
      padding: 0 20px;
      color: var(--font_heavy);
      position: relative;

      @media (max-width: $tablet) {
        padding: 0 10px 0 0;
      }
    }

    &__title {
      display: inline-block;
      margin: 0 20px 0 0;
      width: 100px;
      height: 30px;
      vertical-align: middle;
      background-color: var(--background_light);
      opacity: 0.7;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    &__items {
      display: inline-block;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    &__item {
      display: inline-block;
      width: 130px;
      height: 30px;
      vertical-align: middle;
      background-color: var(--background_light);
      opacity: 0.7;
    }

    &__other-buttons{
      float: right;

      &__main {
        margin-right: 5px;
      }

      svg {
        fill: $pure-white;
        height: 25px;
        vertical-align: middle;
      }

      div {
        display: inline-block;
        padding: 0 10px;
        position: relative;
        cursor: pointer;

        &:last-child {
          padding: 0;
        }
      }

      span {
        position: absolute;
        top: 28px;
        padding-top: 1px;
        display: block;
        font-size: 12px;
        left: 66%;
        color: $pure-white;
        border-radius: 100%;
        background-color: var(--font_light);
        height: 15px;
        text-align: center;
        width: 16px;
        line-height: normal;
      }
    }
  }

.other-buttons {
  &__secondary {
    @media (max-width: $tablet) {
      margin-right: 20px;
    }
  }
}

.cart {
  height: 28px !important;
}

.mobile-menu {
  display: none;

  @media (max-width: $tablet) {
    position: relative;
    display: inline-block;
    width: 30px;
    height: $menu-height;
    padding: 0 20px;
    float: left;
  }

  &__open-button {
    width: 30px;
    height: $menu-height;
    text-align: center;
  }

  &__x {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 30px;
    vertical-align: middle;
    cursor: pointer;

    div {
      position: absolute;
      top: 1px;
      width: 100%;
      height: 3px;
      background-color: $item_name_color;
      border-radius: 1px;

      &:nth-child(2) {
        top: 8px;
      }

      &:last-child {
        top: 15px;
      }
    }
  }
}


</style>
